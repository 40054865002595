import React from "react";
import PropTypes from 'prop-types';

import { injectIntl } from "gatsby-plugin-intl";

const Column = ({intl, column}) => (
    <div className={"column col-lg-"+column.colSize + " col-md-4 " + column.className}>
        <div>
            <img src={column.imagePath} alt={intl.formatMessage({id: column.imageAlt})} />
        </div>
        <p className="column-title">
            {intl.formatMessage({id: column.title})}
        </p>
        {column.subtitle && <p className="column-content">{intl.formatMessage({id: column.subtitle})}</p>}
    </div>
)

Column.propTypes = {
    column: PropTypes.shape({
        colSize: PropTypes.number.isRequired,
        className: PropTypes.string.isRequired,
        imageName: PropTypes.string.isRequired,
        imageAlt: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
    }).isRequired,
};

export default injectIntl(Column);
