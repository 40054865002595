import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from "gatsby-plugin-intl";

import "../styles/corporate-button.less";

const CorporateButton = (props) => (
    <div className="corporate-form-trigger" style={{textAlign: props.alignment}} >
        <a className={props.className} target={props.linkTarget} href={props.url}>{props.intl.formatMessage({id: props.text})}</a>
    </div>
)

CorporateButton.propTypes = {
    linkTarget: PropTypes.string,
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    alignment: PropTypes.string
};

export default injectIntl(CorporateButton);