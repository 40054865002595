import React from 'react';
import { injectIntl } from "gatsby-plugin-intl";

import Section from "./section";
import CorporateButton from "./corporate-button";

class OurClients extends React.Component
{
    componentDidMount()
    {
        window.addEventListener('resize', this.setHeights);
        this.setHeights();
    }

    setHeights()
    {
        const descriptions = document.querySelectorAll('.client-description');
        const lines = document.querySelectorAll('.before-line');
        let maxHeight = 0;
        let lineHeights = [];
        let offset = 60;
        descriptions.forEach((element, index) => {
            /*element.style.minHeight = 0+'px';

            if (element.clientHeight > maxHeight)
            {
                maxHeight = element.clientHeight;
            }*/

            //lineHeights.push(element.clientHeight);
        });

        if (window.innerWidth < 767)
        {
            maxHeight = 0;
        }

        descriptions.forEach((element, index) =>
        {
            element.style.minHeight = maxHeight+'px';
        });

        lines.forEach((element, index) => {
            if (maxHeight === 0)
            {
                lineHeights[index] = 0;
                offset = 0;
            }
            //element.style.height = lineHeights[index]+offset+'px';
        })
    }

    render()
    {
        if (this.props.slim)
        {
            return <Section className="clients-page" title={this.props.intl.formatMessage({id: "Some of the organisations that trust in us"})}>
                <div className="row">
                    <div className={"col-lg-4 col-md-4 lg-1 md-1"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-bbva.png" alt="bbva" />
                            </div>
                            <div className="client-text-container">
                                <div className="before-line"></div>
                                <p className="client-title">BBVA</p>
                                <p className="client-description">{this.props.intl.formatMessage({id: "BBVA relies on us to manage its Fintech acceleration program with more than 50 companies from Spain and Latin America. Our goal is to support the development of companies, using our BusinessUp platform and our Goldsmith® methodology, as well as preparing them to participate in our Texas-Spain investment forum."})}</p>
                                <CorporateButton linkTarget="_blank" className="client-link" text="Know more" url="https://fintech.adventurees.com/" alignment="left" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-4 col-md-4 lg-1 md-1"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-cocacola.png" alt="Coca Cola" />
                            </div>
                            <div className="client-text-container">
                                <div className="before-line"></div>
                                <p className="client-title">Coca Cola / Impact Hub / Gira Weekend</p>
                                <p className="client-description">{this.props.intl.formatMessage({id: "Our 'Investment Roadmap' workshop (or program) is used in Coca Cola's Women's Weekend Tour and Impact Hub program to help women in different Spanish cities understand the different investment channels available for their projects and how they should prepare to present themselves to investors."})}</p>
                                <CorporateButton linkTarget="_blank" className="client-link" text="Know more" url="https://www.cocacolaespana.es/historias/gira-mujeres" alignment="left" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-4 col-md-4 lg-2 md-2"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-startupole.png" alt="Startup Ole" />
                            </div>
                            <div className="client-text-container">
                                <div className="before-line"></div>
                                <p className="client-title">Startup Olé</p>
                                <p className="client-description">{this.props.intl.formatMessage({id: "As part of its new online strategy, international event Startup Olé entrusted us with the creation of matchmaking tool to help registered investors easily and quickly locate their projects of interest."})}</p>
                                <CorporateButton linkTarget="_blank" className="client-link" text="Know more" url="https://startups.startupole.eu/project/search.html" alignment="left" />
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        }

        if(this.props.onlyImg){

            return <Section className="clients-page" >
                <div className="row">
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-1 md-1"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-bbvaic.png" alt="BBVA" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-1 md-1"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-bankia.png" alt="Bankia" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-1 md-1"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-cocacola.png" alt="Cocacola" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-1 md-2"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-utsa.png" alt="UTSA" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-2"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-comillas.png" alt="Comillas" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-sacyr.png" alt="Sacyr" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-2"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-startupole.png" alt="Startup Olé" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-2"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-innsomnia.png" alt="Innsomnia" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-2"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-ecija.png" alt="ECIJA Advisory" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-1 md-1"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-i4w.png" alt="Impulse 4 Woman" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-unltd.png" alt="UNLTD" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-esic.png" alt="ESIC" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-uned.png" alt="UNED" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-ministerio-empleo.png" alt="Ministerio de Empleo" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-ministerio-energia-turismo.png" alt="Ministerio de Energía, Turismo y Agenda Digital" />
                            </div>
                        </div>
                    </div>


                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-1 md-1"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-akaven.png" alt="Akaven" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-latam-connection.png" alt="Latam Connection" />
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-et-institute.png" alt="ET Institute" />
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-imf-business-school.png" alt="IMF Business School" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-fpc-madrid.png" alt="Fundación Parque Científico de Madrid" />
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-face.png" alt="Face" />
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-san-sebastian-gomera.png" alt="San Sebastián de la Gomera" />
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-migration.png" alt="Migration" />
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-ae.png" alt="AE" />
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-adigital.png" alt="Adigital" />
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-comida-invisible.png" alt="Conida Invisivel" />
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-womens-trade-center.png" alt="Women's Trade Center" />
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-aval-am-madrid.png" alt="Aval AM Madrid" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-arca-babel.png" alt="Arca de Babel" />
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-fundacion-dro.png" alt="Fundación Dro" />
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-fundacion-cajacanarias.png" alt="Fundación Cajacanarias" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-ceei-asturias.png" alt="CEEI Asturias" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-3 col-md-4 col-6 mb-4 lg-2 md-3"}>
                        <div className="client">
                            <div className="client-image-container">
                                <img src="/images/clients/logos/client-logo-las-indias.png" alt="CIFP Las Indias" />
                            </div>
                        </div>
                    </div>

                </div>
            </Section>

        }

        return <Section className="clients-page" title={this.props.intl.formatMessage({id: "Some of the organisations that trust in us"})}>
            <div className="row">
                <div className={"col-lg-3 col-md-4 lg-1 md-1 mb-4"}>
                    <div className="client">
                        <div className="client-image-container">
                            <img src="/images/clients/logos/client-logo-bbva.png" alt="bbva" />
                        </div>
                        <div className="client-text-container">
                            <div className="before-line"></div>
                            <p className="client-title">BBVA</p>
                            <p className="client-description">{this.props.intl.formatMessage({id: "BBVA relies on us to manage its Fintech acceleration program with more than 50 companies from Spain and Latin America. Our goal is to support the development of companies, using our BusinessUp platform and our Goldsmith® methodology, as well as preparing them to participate in our Texas-Spain investment forum."})}</p>
                            <CorporateButton linkTarget="_blank" className="client-link" text="Know more" url="https://fintech.adventurees.com/" alignment="left" />
                        </div>
                    </div>
                </div>

                <div className={"col-lg-3 col-md-4 lg-1 md-1 mb-4"}>
                    <div className="client">
                        <div className="client-image-container">
                            <img src="/images/clients/logos/client-logo-cocacola.png" alt="Coca Cola" />
                        </div>
                        <div className="client-text-container">
                            <div className="before-line"></div>
                            <p className="client-title">Coca Cola / Impact Hub / Gira Weekend</p>
                            <p className="client-description">{this.props.intl.formatMessage({id: "Our 'Investment Roadmap' workshop (or program) is used in Coca Cola's Women's Weekend Tour and Impact Hub program to help women in different Spanish cities understand the different investment channels available for their projects and how they should prepare to present themselves to investors."})}</p>
                            <CorporateButton linkTarget="_blank" className="client-link" text="Know more" url="https://www.cocacolaespana.es/historias/gira-mujeres" alignment="left" />
                        </div>
                    </div>
                </div>

                <div className={"col-lg-3 col-md-4 lg-1 md-1 mb-4"}>
                    <div className="client">
                        <div className="client-image-container">
                            <img src="/images/clients/logos/client-logo-startupole.png" alt="Startup Ole" />
                        </div>
                        <div className="client-text-container">
                            <div className="before-line"></div>
                            <p className="client-title">Startup Olé</p>
                            <p className="client-description">{this.props.intl.formatMessage({id: "As part of its new online strategy, international event Startup Olé entrusted us with the creation of matchmaking tool to help registered investors easily and quickly locate their projects of interest."})}</p>
                            <CorporateButton linkTarget="_blank" className="client-link" text="Know more" url="https://startups.startupole.eu/project/search.html" alignment="left" />
                        </div>
                    </div>
                </div>

                <div className={"col-lg-3 col-md-4 lg-1 md-2 mb-4"}>
                    <div className="client">
                        <div className="client-image-container">
                            <img src="/images/clients/logos/client-logo-comillas.png" alt="Comillas" />
                        </div>
                        <div className="client-text-container">
                            <div className="before-line"></div>
                            <p className="client-title">{this.props.intl.formatMessage({id: 'Comillas University'})}</p>
                            <p className="client-description">{this.props.intl.formatMessage({id: 'The Comillas Emprende program is a training contest created by Adventurees Alliance and the University of Comillas. Through our BusinessUp platform we offer a work environment for teams and mentors in which training sessions are hosted. The program is structured in thematic modules to guide learning.'})}</p>
                            <CorporateButton linkTarget="_blank" className="client-link" text="Know more" url="https://comillasemprende.comillas.edu/" alignment="left" />
                        </div>
                    </div>
                </div>                

                <div className={"col-lg-3 col-md-4 lg-2 md-2 mb-4"}>
                <div className="client">
                        <div className="client-image-container">
                            <img src="/images/clients/logos/client-logo-i4w.png" alt="Impulse4Women" />
                        </div>
                        <div className="client-text-container">
                            <div className="before-line"></div>
                            <p className="client-title">Impulse4Women</p>
                            <p className="client-description">{this.props.intl.formatMessage({id: "Impulse4women operates a platform that connects female entrepreneurs from all continents with accelerators and investors from the technology sector. We provide the technological services that guarantee the development and growth of its entrepreneurship ecosystem."})}</p>
                            <CorporateButton linkTarget="_blank" className="client-link" text="Know more" url="https://www.impulse4women.org/" alignment="left" />
                        </div>
                    </div>
                </div>

                <div className={"col-lg-3 col-md-4 lg-2 md-3 mb-4"}>
                    <div className="client">
                        <div className="client-image-container">
                            <img src="/images/clients/logos/client-logo-bhs.png" alt="Blockchain Hub" />
                        </div>
                        <div className="client-text-container">
                            <div className="before-line"></div>
                            <p className="client-title">Blockchain Hub Spain</p>
                            <p className="client-description">{this.props.intl.formatMessage({id: "Blockchain's first project accelerator in Spain uses our Business Up platform to capture and evaluate projects and to help the best of them to develop their business together with the Israeli innovation ecosystem."})}</p>
                            <CorporateButton linkTarget="_blank" className="client-link" text="Know more" url="https://blockchainhubspain.com/" alignment="left" />
                        </div>
                    </div>
                </div>

                <div className={"col-lg-3 col-md-4 lg-2 md-3 mb-4"}>
                    <div className="client">
                        <div className="client-image-container">
                            <img src="/images/clients/logos/client-logo-uiw.png" alt="University of the Incarnate Word" />
                        </div>
                        <div className="client-text-container">
                            <div className="before-line"></div>
                            <p className="client-title">University of the Incarnate Word</p>
                            <p className="client-description">{this.props.intl.formatMessage({id: "UIW teaches university students to set up businesses using our BusinessUp platform and following our methodology, helping professors and students to work on each project idea using the tools we have available."})}</p>
                            <CorporateButton linkTarget="_blank" className="client-link" text="Know more" url="https://uiw.adventurees.com/" alignment="left" />
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    }
}

export default injectIntl(OurClients);
