import React from "react";
import loadable from '@loadable/component';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// Owl's react component is not suited for server side render, so we lazy-load it only in runtime, not when building.
const OwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false })

const Partners = () => {
    const client1 = "/images/clients/logos/client-logo-bbvaic.png";
    const client2 = "/images/clients/logos/client-logo-2.png";
    const client3 = "/images/clients/logos/client-logo-3.png";
    const client4 = "/images/clients/logos/client-logo-4.png";
    const client5 = "/images/clients/logos/client-logo-5.png";
    const client6 = "/images/clients/logos/client-logo-6.png";
    const client7 = "/images/clients/logos/client-logo-7.png";
    const client8 = "/images/clients/logos/client-logo-8.png";
    const client9 = "/images/clients/logos/client-logo-9.png";
    const client10 = "/images/clients/logos/client-logo-10.png";
    const client11 = "/images/clients/logos/client-logo-11.png";
    const client12 = "/images/clients/logos/client-logo-12.png";
    const client13 = "/images/clients/logos/client-logo-13.png";
    const client14 = "/images/clients/logos/client-logo-14.png";
    const client15 = "/images/clients/logos/client-logo-15.png";
    const client16 = "/images/clients/logos/client-logo-16.png";
    const client17 = "/images/clients/logos/client-logo-17.png";
    const client18 = "/images/clients/logos/client-logo-18.png";
    const client19 = "/images/clients/logos/client-logo-19.png";
    const client20 = "/images/clients/logos/client-logo-20.png";
    const client21 = "/images/clients/logos/client-logo-21.png";
    const client22 = "/images/clients/logos/client-logo-22.png";
    const client23 = "/images/clients/logos/client-logo-23.png";
    const client24 = "/images/clients/logos/client-logo-24.png";
    const client25 = "/images/clients/logos/client-logo-25.png";

    const state = {
        responsive: {
            0 : {
                items: 1,
            },
            768 : {
                items: 6,
            }
        }        
    };
    
    return <OwlCarousel
    className="owl-theme"
    loop={true}
    dots={false}
    items={6}
    nav={true}
    autoplay={true}
    autoplayTimeout={2000}
    responsive ={state.responsive}
>
    <div className="client-logo"><img src={client1}  alt="BBVA Innovation Center" /></div>
    <div className="client-logo"><img src={client2}  alt="Sacyr" /></div>
    <div className="client-logo"><img src={client3}  alt="Latam Connection" /></div>
    <div className="client-logo"><img src={client4}  alt={"E&T Institute"} /></div>
    <div className="client-logo"><img src={client5}  alt="IMF Business School" /></div>
    <div className="client-logo"><img src={client6}  alt="ESIC" /></div>
    <div className="client-logo"><img src={client7}  alt="Fundación Parque Científico de Madrid" /></div>
    <div className="client-logo"><img src={client8}  alt="FACE" /></div>
    <div className="client-logo"><img src={client9}  alt="Fundación Fyde CajaCanarias" /></div>
    <div className="client-logo"><img src={client10} alt="Arca de Babel" /></div>
    <div className="client-logo"><img src={client11} alt="Gobierno de España - Ministerio de Empleo y Seguridad Social" /></div>
    <div className="client-logo"><img src={client12} alt="Gobierno de España - Ministerio de Energía, Turismo y Agenda Digital" /></div>
    <div className="client-logo"><img src={client13} alt="" /></div>
    <div className="client-logo"><img src={client14} alt="Migration" /></div>
    <div className="client-logo"><img src={client15} alt="AE" /></div>
    <div className="client-logo"><img src={client16} alt="UNED" /></div>
    <div className="client-logo"><img src={client17} alt="Fundación DRO" /></div>
    <div className="client-logo"><img src={client18} alt="Emprende en Linares" /></div>
    <div className="client-logo"><img src={client19} alt="adigital" /></div>
    <div className="client-logo"><img src={client20} alt="Comida Invisivel" /></div>
    <div className="client-logo"><img src={client21} alt="Women's Trade Center" /></div>
    <div className="client-logo"><img src={client22} alt="Aval Madrid" /></div>
    <div className="client-logo"><img src={client23} alt="UnLtd Spain" /></div>
    <div className="client-logo"><img src={client24} alt="Startup Olé" /></div>
    <div className="client-logo"><img src={client25} alt="Innsomnia" /></div>
</OwlCarousel>
}

export default Partners;